import { Controller } from "stimulus"
import ImagePreviewer from "../../utils/imagepreviewer"

export default class extends Controller {
	static targets = [ "files", "message", "submit", "container", "template", "dropzone" ]
	static values = { maxFiles: { type: Number, default: 2 }, fileMaxSize: { type: Number, default: 5242880 } }

	initialize() {
		this.onReset = this.#onReset.bind(this)
		this.onDragOver = this.#onDragOver.bind(this)
		this.onDragEnter = this.#onDragEnter.bind(this)
		this.onDrop = this.#onDrop.bind(this)
		this.isSubmitting = false
	}

	connect() {
		this.previewer = new ImagePreviewer(this.containerTarget, this.templateTarget)
		this.previewer.render(this.filesTarget.files)

		this.form.addEventListener("reset", this.onReset)
		this.checkValidity()
		this.#setupDragAndDrop()
	}

	disconnect() {
		this.form.removeEventListener("reset", this.onReset)
		this.#disconnectDragAndDrop()
	}

	onSubmit(event) {
		if (this.isSubmitting) {
			event.preventDefault()
			return
		}

		// basically we will disable the button to avoid double submit of form
		this.submitTarget.classList.add(...this.buttonDisabledClasses)
		this.isSubmitting = true
	}

	onChange() {
		this.checkValidity()

		const files = Array.from(this.filesTarget.files)
		// first we will check if there's any file that have a bigger file size than the one defined
		const filtered = files.filter((file) => file.size <= this.fileMaxSizeValue).slice(0, this.maxFilesValue)

		if (files.length !== filtered.length) {
			const dataTransfer = new DataTransfer()
			filtered.forEach((file) => dataTransfer.items.add(file))
			this.filesTarget.files = dataTransfer.files
			this.#showError()
		}

		this.previewer.render(this.filesTarget.files)
	}

	checkValidity() {
		const isValid = this.messageTarget.value !== "" || this.filesTarget.files.length > 0
		if (isValid) {
			this.submitTarget.classList.remove(...this.buttonDisabledClasses)
		} else {
			this.submitTarget.classList.add(...this.buttonDisabledClasses)
		}
	}

	removeFile(event) {
		const button = event.target
		const preview = button.closest("[data-preview]")
		const previewIndex = Array.from(this.containerTarget.children).indexOf(preview)
		const files = Array.from(this.files).filter((file, index) => index !== previewIndex)
		const dataTransfer = new DataTransfer()
		files.forEach((file) => dataTransfer.items.add(file))
		this.filesTarget.files = dataTransfer.files
		this.checkValidity()
		preview.remove()
	}

	#appendFiles(files) {
		const currentFiles = Array.from(this.filesTarget.files)
		if (currentFiles.length > this.maxFilesValue) {
			this.#showError()
			return
		}

		const dataTransfer = new DataTransfer()
		currentFiles.forEach((file) => dataTransfer.items.add(file))

		const newFiles = Array.from(files)
		const allowedFiles = newFiles.filter((file) => file.size <= this.fileMaxSizeValue)
		if (allowedFiles.length === 0) {
			this.#showError()
			return
		}

		const maxFilesAllowed = Math.abs(this.maxFilesValue - currentFiles.length)
		if (maxFilesAllowed < allowedFiles.length) this.#showError()
		allowedFiles.slice(0, maxFilesAllowed).forEach((file) => dataTransfer.items.add(file))

		this.filesTarget.files = dataTransfer.files
		this.checkValidity()
		this.previewer.render(this.filesTarget.files)
	}

	#onReset() {
		this.containerTarget.innerHTML = ""
		this.isSubmitting = false
		setTimeout(() => this.checkValidity(), 50)
	}

	#setupDragAndDrop() {
		// we will setup drop files in the total conversation element, so we will find the closest `[data-controller="chat--conversation"]`
		this.conversation = this.element.closest('[data-controller="chat--conversation"]')
		this.conversation.addEventListener("dragover", this.onDragOver)
		this.conversation.addEventListener("dragenter", this.onDragEnter)
		this.conversation.addEventListener("drop", this.onDrop)
	}

	#disconnectDragAndDrop() {
		this.conversation.removeEventListener("dragover", this.onDragOver)
		this.conversation.removeEventListener("dragenter", this.onDragEnter)
		this.conversation.removeEventListener("drop", this.onDrop)
	}

	// we need to use this otherwise when the drop happens it displays file in browser instead of triggering
	// drop event
	#onDragOver(event) {
		event.preventDefault()
	}

	#onDragEnter(event) {
		event.preventDefault()
		this.dropzoneTarget.classList.remove("hidden")
		this.dropzoneTarget.addEventListener("dragleave", () => this.dropzoneTarget.classList.add("hidden"), { once: true })
	}

	#onDrop(event) {
		event.preventDefault()
		const validFileTypes = this.filesTarget.accept.split(", ")
		const files = Array.from(event.dataTransfer.files).filter((file) => validFileTypes.includes(file.type))
		this.dropzoneTarget.classList.add("hidden")
		this.#appendFiles(files)
	}

	#showError() {
		alertify.error("Uploads are limited to 5MB per image, and only 2 images can be uploaded per message.")
	}

	get form() {
		return this.element.closest("form")
	}

	get files() {
		return this.filesTarget.files
	}

	get buttonDisabledClasses() {
		return ["pointer-events-none", "opacity-50"]
	}
}
