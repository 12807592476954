import { BridgeComponent } from "@hotwired/strada"

export default class extends BridgeComponent {
	static component = "passes"

	onClick(event) {
		event.preventDefault()
		const passUrl = event.currentTarget.href
		this.send("connect", { passUrl })
	}
}
