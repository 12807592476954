import { Controller } from "stimulus"
import { useIntersection } from "stimulus-use"

export default class extends Controller {
	static targets = ["controlsTemplate", "progress", "controls", "track", "item"]
	static classes = ["bullet", "bulletActive", "bulletInactive"]
	static values = { animatedScroll: { type: Boolean, default: true }, startsAt: { type: Number, default: 0 }, autoFocus: { type: Boolean, default: false } }

	connect() {
		if (!this.hasControlsTarget) this.#addControls()
		if (this.shouldHaveProgress) this.#setupProgress()

		// now we setup an intersection observer whenever the slider changes
		this.itemTargets.forEach((item) => useIntersection(this, { element: item, root: this.trackTarget, threshold: 0.9 }))

		if (this.startsAtValue > 1) this.#move(this.startsAtValue - 1)
		if (this.autoFocusValue) this.element.focus()

		// we are using `turbo:before-cache` because the `disconnect` method doesn't appear to be working effectively
		document.addEventListener("turbo:before-cache", () => {
			if (this.hasControlsTarget) this.controlsTarget.remove()
			if (this.hasProgressTarget) this.progressTarget.innerHTML = ""
		}, { once: true })
	}

	appear(entry) {
		if (this.shouldHaveProgress) this.#updateBullets(entry.target)
	}

	previous() {
		const currentIndex = this.itemTargets.findIndex((element) => element === this.currentItem)
		this.#move(currentIndex === 0 ? this.itemTargets.length - 1 : currentIndex - 1)
	}

	next() {
		const currentIndex = this.itemTargets.findIndex((element) => element === this.currentItem)
		this.#move(currentIndex === this.itemTargets.length - 1 ? 0 : currentIndex + 1)
	}

	#move(index) {
		const target = this.itemTargets[index]
		this.trackTarget.scrollTo({ left: target.offsetLeft, behavior: this.scrollBehavior })
	}

	#addControls() {
		// we don't add any controls if we don't have more than 1 item available
		if (this.itemTargets.length < 2) return

		const content = this.controlsTemplateTarget.content.cloneNode(true)
		this.element.appendChild(content)
	}

	#setupProgress() {
		const current = this.currentItem

		this.itemTargets.forEach((item) => {
			const bullet = document.createElement("div")
			bullet.classList.add(...this.bulletClasses)
			bullet.classList.add(...(current === item ? this.bulletActiveClasses : this.bulletInactiveClasses))
			this.progressTarget.appendChild(bullet)
		})
	}

	#updateBullets(current) {
		const currentIndex = this.itemTargets.findIndex((element) => element === current)
		Array.from(this.progressTarget.children).forEach((element, index) => {
			if (currentIndex === index) {
				element.classList.remove(...this.bulletInactiveClasses)
				element.classList.add(...this.bulletActiveClasses)
			} else {
				element.classList.remove(...this.bulletActiveClasses)
				element.classList.add(...this.bulletInactiveClasses)
			}
		})
	}

	get currentItem() {
		return this.itemTargets[Math.ceil(this.trackTarget.scrollLeft / this.trackTarget.clientWidth)]
	}

	get shouldHaveProgress() {
		return this.hasProgressTarget && this.itemTargets.length > 1
	}

	get scrollBehavior() {
		if (this.animatedScroll) return window.matchMedia("(prefers-reduced-motion: reduce)").matches ? "instant" : "smooth"
		return "instant"
	}
}
