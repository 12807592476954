import { Controller } from "stimulus"
import { useClickOutside } from "stimulus-use"
import ScriptLoader from "../../utils/scriptloader"

import { Picker } from "emoji-picker-element";

export default class extends Controller {
	static targets = [ "button", "textarea", "picker" ]

	initialize() {
		this.onEmojiPick = this.#onEmojiPick.bind(this)
	}

	connect() {
		this.#initializePicker()
		this.buttonTarget.setAttribute("disabled", "")
		this.pickerTarget.addEventListener("emoji-click", this.onEmojiPick)

		ScriptLoader.loadScript('popper', () => {
			// we only will enable the button after we have popper initialized
			this.buttonTarget.removeAttribute("disabled")
			this.popper = Popper.createPopper(
				this.buttonTarget,
				this.pickerTarget,
				{
					placement: "top-start",
					modifiers: [
						{ name: "offset", options: { offset: [0, 12] } }
					]
				}
			)

			useClickOutside(this, { element: this.pickerTarget })
		})
	}

	disconnect() {
		this.pickerTarget.removeEventListener("emoji-click", this.onEmojiPick)
	}

	open() {
		this.pickerTarget.setAttribute("data-show", "")
		this.popper.update()
	}

	clickOutside() {
		this.closePicker()
	}

	closePicker() {
		this.pickerTarget.removeAttribute("data-show")
	}

	#initializePicker() {
		// little hack to avoid console errors for indexeddb transactions, so we store it in the window object
		// so that we can reference it later, instead of initializing always the picker, for more information reference
		// https://github.com/nolanlawson/emoji-picker-element/issues/329
		if (window.emojiPicker) {
			this.picker = window.emojiPicker
		} else {
			this.picker = new Picker()
			window.emojiPicker = this.picker
		}

		this.pickerTarget.appendChild(this.picker)
	}

	#onEmojiPick(event) {
		const emoji = event.detail.unicode
		const value = this.textareaTarget.value
		const firstPart = value.substring(0, this.textareaTarget.selectionEnd)
		const secondPart = value.substring(this.textareaTarget.selectionEnd)
		this.textareaTarget.value = `${firstPart}${emoji}${secondPart}`
		this.closePicker()
	}
}
