import { Controller } from "stimulus"
import Sortable from "sortablejs"
import { useMutation } from "stimulus-use"

export default class extends Controller {
	static targets = [ "container", "button" ]
	static values = { minimumImages: { type: Number, default: 1 } }

	initialize() {
		this.onFormChange = this.#onFormChange.bind(this)
	}

	connect() {
		this.sortable = new Sortable(this.containerTarget, { group: "images", handle: ".drag-handle", onSort: () => this.#reorder() })
		this.#checkButtonStatus()
		this.form.addEventListener("change", this.onFormChange)
		useMutation(this, { element: this.containerTarget, childList: true })
	}

	disconnect() {
		this.form.removeEventListener("change", this.onFormChange)
	}

	mutate() {
		this.#checkButtonStatus()
	}

	#reorder() {
		// we need to loop through children and check if and update position, but we need to exclude any block that have
		// field `_destroy` to true (or more specifically to 1)
		let position = 1
		this.images.forEach((image) => {
			const destroyField = image.querySelector('input[name*="_destroy"]')
			if (destroyField.value === "1") return

			const positionField = image.querySelector('input[name*="position"]')
			if (positionField) positionField.value = position
			position += 1
		})
	}

	#onFormChange() {
		this.#checkButtonStatus()
	}

	#checkButtonStatus() {
		if (!this.hasButtonTarget) return

		// basically the button will be disabled if the form is invalid because that means that we have a file input which was not
		// uploaded yet
		this.buttonTarget.toggleAttribute("disabled", !this.form.checkValidity())
		this.#checkRemoveAbility()
	}

	#checkRemoveAbility() {
		const shouldDisableRemove = this.images.length <= this.minimumImagesValue
		this.images.forEach((image) => {
			const removeButton = image.querySelector(".remove-image")
			if (!removeButton) return

			if (shouldDisableRemove) {
				removeButton.classList.add(...this.disabledClasses)
			} else {
				removeButton.classList.remove(...this.disabledClasses)
			}
		})
	}

	get images() {
		return Array.from(this.containerTarget.children)
	}

	get form() {
		return this.element.closest("form")
	}

	get disabledClasses() {
		return ['opacity-50', 'pointer-events-none']
	}
}
