import { Controller } from "stimulus"

export default class extends Controller {
	connect() {
		if (history.length <= 2) return

		this.element.addEventListener("click", (event) => {
			event.preventDefault()
			history.back()
		}, { once: true })
	}
}
