import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
	static targets = [ "image", "progress", "preview" ]
	static values = { url: String }

	onChange(event) {
		const input = event.target

		// we will track how many files we have already uploaded and the total so that we can check when we can
		// change the form busy attribute
		this.uploaded = 0
		this.total = input.files.length
		if (this.total === 0) return

		input.removeAttribute("required")

		// if we are handling a single file input we will want to clear any hidden field because this image will replace the
		// previous one
		if (!input.multiple) this.#clearHidden(input.name)
		// we will mark the form as busy so that we can do some styling with it eventually
		this.#triggerBusy("processing", input)

		// now we will loop through each file and use direct upload to upload them to service
		Array.from(input.files).forEach(file => this.#uploadFile(file, input))

		// we then reset the input field because otherwise the files would be uploaded again and we don't
		// that to happen
		input.value = null
	}

	#uploadFile(file, input) {
		const upload = new DirectUpload(file, this.urlValue, this)

		if (this.hasPreviewTarget) this.previewTarget.classList.add("opacity-50")

		upload.create((error, blob) => {
			if (error) {
				alertify.error("There was a problem uploading your image, please try again")
			} else {
				const hiddenField = document.createElement("input")
				hiddenField.setAttribute("type", "hidden")
				hiddenField.setAttribute("value", blob.signed_id)
				hiddenField.name = input.name
				this.form.appendChild(hiddenField)
				this.image = new Image()
				this.image.src = this.blobUrl.replace(":signed_id", blob.signed_id).replace(":filename", blob.filename)
				this.image.onload = () => {
					this.element.closest(".image-uploader").querySelector("img").src = this.image.src
				}
			}

			this.uploaded += 1
			if (this.uploaded === this.total) this.#triggerBusy("processed", input)
		})
	}

	// integrate with DirectUpload so that we can track progress of upload
	directUploadWillStoreFileWithXHR(request) {
		request.upload.addEventListener("progress", event => this.#directUploadDidProgress(event))
	}

	#directUploadDidProgress(event) {
		if (!this.hasProgressTarget) return

		const progress = event.loaded / event.total * 100
		this.progressTarget.style.width = `${progress}%`
		this.progressTarget.removeAttribute("hidden")
		if (progress === 100) setTimeout(() => {
			this.progressTarget.setAttribute("hidden", "")
			if (this.hasPreviewTarget) this.previewTarget.classList.remove("opacity-50")
		}, 250)
	}

	#clearHidden(name) {
		const fields = this.form.querySelectorAll(`input[type="hidden"][name="${name}"]`)
		fields.forEach(field => field.remove())
	}

	#triggerBusy(status, input) {
		if (status === "processing") {
			this.form.setAttribute("busy", "")
		} else {
			this.form.removeAttribute("busy")
		}

		input.dispatchEvent(new CustomEvent("form:busy", { bubbles: true, detail: { status: status } }))
	}

	get blobUrl() {
		return window.App.Routes.blobUrl
	}

	get form() {
		return this.element.closest("form")
	}
}
