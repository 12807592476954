import { Controller } from "stimulus"
// import Sortable from "sortablejs"

export default class extends Controller {
	static targets = [ "nodes", "form", "save", "publish", "preview" ]

	initialize() {
		this.onChange = this.#onChange.bind(this)
	}

	connect() {
		// REVIEW: right now because the experience feels clunky we are disabling the sort ability at the content nodes level
		//
		// this.sortable = new Sortable(
		// 	this.nodesTarget,
		// 	{
		// 		group: "nodes",
		// 		handle: ".drag-handle",
		// 		onSort: () => this.#reorder()
		// 	}
		// )

		this.element.addEventListener("change", this.onChange)
		this.element.addEventListener("trix-change", this.onChange)
		this.#checkFormsValidity()
	}

	disconnect() {
		this.element.removeEventListener("change", this.onChange)
		this.element.removeEventListener("trix-change", this.onChange)
	}

	// we will just hide any submit button from the forms, because we will use a unified button instead of allow saving
	// each form individually
	formTargetConnected(element) {
		const submitButtons = element.querySelectorAll('[type="submit"]')
		submitButtons.forEach((button) => button.classList.add("hidden"))

		if (element.dataset.node === "new") {
			element.scrollIntoView()
			this.#reorder()
			this.#checkFormsValidity()
		}
	}

	// nodes can trigger a `busy` event when are doing some operations, like uploading images to storage services, so we will
	// listen to it and change the disabled status of the save/publish buttons
	handleBusyChange(event) {
		this.#toggleButtons(event.detail.status === "processing")
	}

	remove(event) {
		const button = event.target

		// basically depending on node name we will have different approaches, if we have an anchor `<a>` we will do a Turbo Stream
		// fetch request because the node already exists so we can't just remove it from the DOM we need to mark it for deletion
		if (button.nodeName === "A") {
			event.preventDefault()
			fetch(button.href, { headers: { Accept: "text/vnd.turbo-stream.html" } })
				.then(r => r.text())
				.then(html => {
					Turbo.renderStreamMessage(html)
					setTimeout(() => {
						this.#reorder()
						this.#checkFormsValidity()
					}, 50)
				})
		} else {
			button.closest("form").remove()
			this.#reorder()
			this.#checkFormsValidity()
		}
	}

	save() {
		this.#save()
	}

	publish(event) {
		event.preventDefault()
		this.#save(true)
	}

	#onChange() {
		this.#checkFormsValidity()
	}

	#checkFormsValidity() {
		const valid = Array.from(this.formTargets).every((form) => form.checkValidity())
		this.#toggleButtons(!valid)
		if (!valid) this.#togglePreview(true)
	}

	#toggleButtons(disabled) {
		if (this.hasPublishTarget) this.publishTarget.toggleAttribute("disabled", disabled)
		if (this.hasSaveTarget) this.saveTarget.toggleAttribute("disabled", disabled)
	}

	#togglePreview(disabled) {
		if (this.hasPreviewTarget) this.previewTarget.toggleAttribute("disabled", disabled)
	}

	#reorder() {
		// now we should reorder the forms that aren't marked to be deleted
		const formElements = this.formTargets.filter((form) => form.hasAttribute("data-node") && !form.hasAttribute("hidden"))
		formElements.forEach((element, index) => {
			const positionField = element.querySelector('[name="node[position]"')
			if (positionField) positionField.value = index + 1
		})
	}

	#save(publish = false) {
		const anyBusy = Array.from(this.formTargets).some(form => form.hasAttribute("busy"))
		if (anyBusy) return

		const allValid = Array.from(this.formTargets).every(form => form.checkValidity())
		if (!allValid) {
			// REVIEW: we need to check this ... probably we need a better error message
			alertify.error("Make sure all fields are valid")
			return
		}

		// we disable save/publish buttons because we don't want users to
		// clicking buttons like there's no tomorrow
		this.#checkFormsValidity()
		this.#toggleButtons(true)
		this.#togglePreview(true)
		this.#makeRequest(this.formTargets, publish)
	}

	async #makeRequest(forms, publish = false) {
		let succeeded = 0

		for (let i = 0; i < forms.length; i++) {
			const response = await fetch(forms[i].action, { method: "POST", headers: { Accept: "text/vnd.turbo-stream.html" }, body: new FormData(forms[i]) })
			if (response.ok) succeeded += 1

			const html = await response.text()
			Turbo.renderStreamMessage(html)
		}

		if (succeeded != forms.length) {
			this.#toggleButtons(false)
			return
		}

		if (publish) {
			this.publishTarget.closest("form").requestSubmit()
		} else {
			alertify.notify("Collection updated")
			this.#toggleButtons(false)
			this.#togglePreview(false)
		}
	}
}
