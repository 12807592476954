/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require.context('../images', true)

import Rails from '@rails/ujs';
Rails.start();

import * as Turbo from "@hotwired/turbo"
import TurboPower from "turbo_power"

import "@rails/actiontext"
import "trix"

import "qrjs2"
import "lazysizes"
import "vanilla-cookieconsent"
import "css/application"
import "controllers"

import "channels/consumer"
import "channels/notifications_channel"

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

TurboPower.initialize(Turbo.StreamActions)

/**
 * because there's a chance that some users don't have browsers that support native <dialog>
 * elements we will check if the `HTMLDialogElement` is available and only use our custom dialog
 * if it's, otherwise it will default to browser native confirmation dialog
 */
if (typeof HTMLDialogElement === "function") {
	Turbo.config.forms.confirm = (message) => {
		const dialog = document.getElementById("turbo-confirm")
		const body = document.body
		body.classList.add("overflow-hidden")

		dialog.querySelector("p").textContent = message
		dialog.showModal()

		return new Promise((resolve) => {
			dialog.addEventListener("close", () => {
				body.classList.remove("overflow-hidden")
				if (dialog.returnValue === "confirm") resolve(true)
			}, { once: true })
		})
	}
}

document.addEventListener("turbo:load", () => {
	// we need to check if the browser supports natively dialogs or not, if the browser does not support it we just
	// remove the dialog from the document
	if (typeof HTMLDialogElement === "undefined") {
		const dialog = document.getElementById("turbo-confirm")
		if (dialog) dialog.remove()
	}

	const emailLinks = document.querySelectorAll('.email[href^="mailto:"]')
	emailLinks.forEach((emailLink) => {
		emailLink.href = emailLink.href.replace("mailto:", `mailto:${emailLink.innerText}`)
	})
})

document.addEventListener("trix-file-accept", (event) => event.preventDefault())
