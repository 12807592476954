import { Controller } from "stimulus"

export default class extends Controller {
	connect() {
		// we will use dark mode, so we need to enable class to achieve that
		document.body.classList.add("c_darkmode")
		this.#checkHash()
		this.#doLoad()
	}

	disconnect() {
		// we need to retrieve the settings consent modal and remove it if it exists
		const settingsConsentModal = document.querySelector("#cc_div")
		if (settingsConsentModal) settingsConsentModal.remove()
	}

	showSettings(event) {
		event.preventDefault()
		this.#doLoad()
		this.cookieManager.showSettings()
	}

	#autoAcceptTurboIos() {
		if (this.#isTurboNativeiOS()) {
			if (document.cookie.includes("att_accepted=")) {
					this.cookieManager.accept('all')
			}else if (document.cookie.includes("att_rejected=")) {
					this.cookieManager.accept('all', ['analytics'])
			}

		}
	}

	#isTurboNativeiOS() {
		return window.navigator.userAgent.includes("Turbo Native iOS")
	}

	#doLoad() {
		// and then we will configure the plugin
		this.cookieManager.run({
			autorun: this.#isTurboNativeiOS() ? false : true,
			current_lang: "en",
			autoclear_cookies: true,
			page_scripts: true,
			gui_options: {
				consent_modal: {
					swap_buttons: true
				}
			},
			onChange: function() {
				// when changes on cookies happen we refresh the page as a safety measure
				window.location.reload()
			},
			languages: {
				en: {
					consent_modal: {
						title: "We’ve baked some cookies!",
						description: "Essential cookies ensure the proper operation of our website, while tracking cookies allow us to understand how you interact with it. We need your consent to use both. <button type=\"button\" data-cc=\"c-settings\" class=\"cc-link\">Manage options</button>.",
						primary_btn: { text: "Accept all", role: "accept_all" },
						secondary_btn: { text: "Reject all", role: "accept_necessary" }
					},
					settings_modal: {
						title: "Cookie settings",
						save_settings_btn: "Save settings",
						accept_all_btn: "Accept all",
						reject_all_btn: "Reject all",
						close_btn_label: "Close",
						cookie_table_headers: [
							{ col1: "Name" },
							{ col2: "Expiration" },
							{ col3: "Description" }
						],
						blocks: [
							{
								title: "Cookie usage 📢",
								description: "We use cookies to ensure the website's basic functionalities and enhance your online experience. You can choose to opt in/out for each category at any time. Please read the full <a href=\"/privacy-policy\" class=\"cc-link\">privacy policy</a> for more details about cookies and other sensitive data."
							},
							{
								title: "Strictly necessary cookies",
								description: "These cookies are essential for the proper functioning of our website. Without these cookies, the website would not work properly.",
								toggle: { value: "necessary", enabled: true, readonly: true }
							},
							{
								title: "Analytics and targeting cookies",
								description: "These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymous and cannot be used to identify you.",
								toggle: { value: "analytics", enabled: false, readonly: false },
								cookie_table: [
									{ col1: "^_ga", col2: "2 years", col3: "To store and track visits across website.", is_regex: true },
									{ col1: "_gcl_au", col2: "3 months", col3: "To store and track conversions." },
									{ col1: "_fbp", col2: "3 months", col3: "To store and track visits across website." }
								]
							},
							{
								title: "More information",
								description: "For any queries in relation to our policy on cookies and your choices, please <a class=\"cc-link\" href=\"/contact-us\">contact us</a>.",
							}
						]
					}
				}
			}
		})

		this.#autoAcceptTurboIos()
	}

	#checkHash() {
		if (window.location.hash === "#CookieSettings") {
			this.#doLoad()
			this.cookieManager.showSettings()
		}
	}

	get cookieManager() {
		if (!this.cc) this.cc = initCookieConsent()

		return this.cc
	}
}
