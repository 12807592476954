class ImagePreviewer {
		constructor(target, template = null) {
		this.target = target
		this.template = template
	}

	render(files) {
		// we start by clearing stage before rendering files
		this.target.innerHTML = ""
		// just because if the files list is not an array stuff will break, so we ensure that we are dealing with an array
		files = Array.from(files)
		files.forEach((file) => this.#showPreview(file))
	}

	#showPreview(file) {
		const reader = new FileReader()
		reader.onload = () => {
			let element = null
			let image = null
			// if a template element was provided we will use it basically we will find the `<img />` inside and use that to place the
			// image, if no template is supplied we will use a sensible default
			if (this.template) element = this.template.content.cloneNode(true)

			if (element) {
				image = element.querySelector("img")
			} else {
				image = document.createElement("img")
				image.classList.add("h-full", "object-cover", "w-full")
				element = image
			}

			image.src = reader.result
			this.target.appendChild(element)
		}
		reader.readAsDataURL(file)
	}
}

export default ImagePreviewer
