import { Dropdown } from "tailwindcss-stimulus-components"

export default class Accordion extends Dropdown {
	_show(cb) {
		super._show(cb)
	}

	_collapseAccordion() {
		this.accordions.forEach(item => {
			if( item.hasAttribute('aria-expanded') && item.getAttribute('aria-expanded') == 'true') {
				this.application.getControllerForElementAndIdentifier(item, 'accordion').toggle()
			}
		})
	}

	get accordions() {
		let accordions = []

		if(!this.element.parentNode) {
			return accordions
		}

		let accordion  = this.element.parentNode.firstChild

		while (accordion) {
			if (accordion.nodeType === 1 && accordion !== this.element) {
				accordions.push(accordion)
			}
			accordion = accordion.nextSibling
		}

		return accordions
	}
}
