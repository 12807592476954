import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "output", "input" ]

	connect() {
		this.show()
	}

	show() {
		let video = null

		const regexes = {
			youtube: /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?|.+\?.+)?\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
			vimeo: /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/)(\d+)/
		}

		const url = this.inputTarget.value
		Object.keys(regexes).forEach((source) => {
			const matches = url.match(regexes[source])
			if (matches) video = { provider: source, id: matches[1] }
		})

		if (video) this.outputTarget.innerHTML = `<iframe width="100%" height="100%" src="${this.#videoURL(video)}"></iframe>`
	}

	#videoURL(video) {
		if (video.provider === "youtube") {
			return `https://www.youtube.com/embed/${video.id}`
		} else {
			return `https://player.vimeo.com/video/${video.id}`
		}
	}
}
