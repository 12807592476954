import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "uploadArea", "profilePictureArea",  "form", "uploadPanel", "nestableArea" ]

	initialize() {
		this.onUploadImage = this._onUploadImage.bind(this)
		this.onUploadProfileImage = this._onUploadProfileImage.bind(this)
	}

	connect() {
		this.uploadAreaTargets.forEach( item =>  {
			item.querySelector("input[type='file']").addEventListener("change", this.onUploadImage)
		})

		if (this.hasProfilePictureAreaTarget)
			this.profilePictureAreaTarget.querySelector("input[type='file']").addEventListener("change", this.onUploadProfileImage)

		$(this.uploadPanelTarget).on('nested-fields:after-insert', function(e) {
			e.detail.element.querySelector("input[type='file']").addEventListener("change", this.onUploadImage)
		}.bind(this));
	}

	_onUploadImage(e) {
		let file = e.target.files[0];

		if (file) {
			let fileSize = file.size / 1024;
			let fileType = file.type;

			if (fileType.match(/image\/(jpg|png|jpeg)/) && fileSize <= 5120) {
				// document.getElementById("portfolio-update").value = 'upload'
				this.formTarget.requestSubmit()
			} else {
				alertify.error("File not allowed, make sure it is an PNG or JPG with less than 5MB.")
			}
		}
	}

	_onUploadProfileImage(e) {

		let file = e.target.files[0];

		if (file) {
			let fileSize = file.size / 1024;
			let fileType = file.type;

			if (fileType.match(/image\/(jpg|png|jpeg)/) && fileSize <= 800) {
				document.getElementById("portfolio-update").value = 'upload'
				this.formTarget.requestSubmit()
			} else {
				alertify.error("File not allowed, make sure it is an PNG or JPG with less than 800KB.")
			}
		}
	}

	removeImage(e) {
		e.preventDefault()
		let parent = e.target.closest('.user-uploads')
		if (parent.classList.contains('to-destroy')) return

		parent.classList.add('to-destroy')
		parent.querySelector('input[name*=_destroy]').value = true;

		document.getElementById("portfolio-update").value = 'remove'

		document.getElementById("portfolio-update").querySelectorAll('draggable-item').forEach( item => {
			if (item.dataset.order > e.target.closest('.draggable-item').dataset.order) {
				item.dataset.order -= 1;
			}
		})

		this.formTarget.requestSubmit()
	}
}
